import { GenIcon } from '../lib';

export function LuCalendar (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"rect","attr":{"width":"18","height":"18","x":"3","y":"4","rx":"2","ry":"2"}},{"tag":"line","attr":{"x1":"16","x2":"16","y1":"2","y2":"6"}},{"tag":"line","attr":{"x1":"8","x2":"8","y1":"2","y2":"6"}},{"tag":"line","attr":{"x1":"3","x2":"21","y1":"10","y2":"10"}}]})(props);
};
export function LuHistory (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"}},{"tag":"path","attr":{"d":"M3 3v5h5"}},{"tag":"path","attr":{"d":"M12 7v5l4 2"}}]})(props);
};
export function LuPowerOff (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M18.36 6.64A9 9 0 0 1 20.77 15"}},{"tag":"path","attr":{"d":"M6.16 6.16a9 9 0 1 0 12.68 12.68"}},{"tag":"path","attr":{"d":"M12 2v4"}},{"tag":"path","attr":{"d":"m2 2 20 20"}}]})(props);
};
export function LuCalendarDays (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"rect","attr":{"width":"18","height":"18","x":"3","y":"4","rx":"2","ry":"2"}},{"tag":"line","attr":{"x1":"16","x2":"16","y1":"2","y2":"6"}},{"tag":"line","attr":{"x1":"8","x2":"8","y1":"2","y2":"6"}},{"tag":"line","attr":{"x1":"3","x2":"21","y1":"10","y2":"10"}},{"tag":"path","attr":{"d":"M8 14h.01"}},{"tag":"path","attr":{"d":"M12 14h.01"}},{"tag":"path","attr":{"d":"M16 14h.01"}},{"tag":"path","attr":{"d":"M8 18h.01"}},{"tag":"path","attr":{"d":"M12 18h.01"}},{"tag":"path","attr":{"d":"M16 18h.01"}}]})(props);
};
export function LuSettings (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"}},{"tag":"circle","attr":{"cx":"12","cy":"12","r":"3"}}]})(props);
};
export function LuDownload (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"}},{"tag":"polyline","attr":{"points":"7 10 12 15 17 10"}},{"tag":"line","attr":{"x1":"12","x2":"12","y1":"15","y2":"3"}}]})(props);
};
export function LuBellRing (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"}},{"tag":"path","attr":{"d":"M10.3 21a1.94 1.94 0 0 0 3.4 0"}},{"tag":"path","attr":{"d":"M4 2C2.8 3.7 2 5.7 2 8"}},{"tag":"path","attr":{"d":"M22 8c0-2.3-.8-4.3-2-6"}}]})(props);
};
export function LuMailOpen (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z"}},{"tag":"path","attr":{"d":"m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10"}}]})(props);
};
export function LuImport (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M12 3v12"}},{"tag":"path","attr":{"d":"m8 11 4 4 4-4"}},{"tag":"path","attr":{"d":"M8 5H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-4"}}]})(props);
};
export function LuCalendarClock (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5"}},{"tag":"path","attr":{"d":"M16 2v4"}},{"tag":"path","attr":{"d":"M8 2v4"}},{"tag":"path","attr":{"d":"M3 10h5"}},{"tag":"path","attr":{"d":"M17.5 17.5 16 16.25V14"}},{"tag":"path","attr":{"d":"M22 16a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"}}]})(props);
};
export function LuCalendarX2 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M21 13V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8"}},{"tag":"line","attr":{"x1":"16","x2":"16","y1":"2","y2":"6"}},{"tag":"line","attr":{"x1":"8","x2":"8","y1":"2","y2":"6"}},{"tag":"line","attr":{"x1":"3","x2":"21","y1":"10","y2":"10"}},{"tag":"line","attr":{"x1":"17","x2":"22","y1":"17","y2":"22"}},{"tag":"line","attr":{"x1":"17","x2":"22","y1":"22","y2":"17"}}]})(props);
};
export function LuAlarmClock (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"circle","attr":{"cx":"12","cy":"13","r":"8"}},{"tag":"path","attr":{"d":"M12 9v4l2 2"}},{"tag":"path","attr":{"d":"M5 3 2 6"}},{"tag":"path","attr":{"d":"m22 6-3-3"}},{"tag":"path","attr":{"d":"M6.38 18.7 4 21"}},{"tag":"path","attr":{"d":"M17.64 18.67 20 21"}}]})(props);
};
export function LuRefreshCcw (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"}},{"tag":"path","attr":{"d":"M3 3v5h5"}},{"tag":"path","attr":{"d":"M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16"}},{"tag":"path","attr":{"d":"M16 16h5v5"}}]})(props);
};
export function LuExternalLink (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"}},{"tag":"polyline","attr":{"points":"15 3 21 3 21 9"}},{"tag":"line","attr":{"x1":"10","x2":"21","y1":"14","y2":"3"}}]})(props);
};
export function LuReply (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"polyline","attr":{"points":"9 17 4 12 9 7"}},{"tag":"path","attr":{"d":"M20 18v-2a4 4 0 0 0-4-4H4"}}]})(props);
};
export function LuReplyAll (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"polyline","attr":{"points":"7 17 2 12 7 7"}},{"tag":"polyline","attr":{"points":"12 17 7 12 12 7"}},{"tag":"path","attr":{"d":"M22 18v-2a4 4 0 0 0-4-4H7"}}]})(props);
};
export function LuUpload (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"}},{"tag":"polyline","attr":{"points":"17 8 12 3 7 8"}},{"tag":"line","attr":{"x1":"12","x2":"12","y1":"3","y2":"15"}}]})(props);
};
export function LuCopyPlus (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"line","attr":{"x1":"15","x2":"15","y1":"12","y2":"18"}},{"tag":"line","attr":{"x1":"12","x2":"18","y1":"15","y2":"15"}},{"tag":"rect","attr":{"width":"14","height":"14","x":"8","y":"8","rx":"2","ry":"2"}},{"tag":"path","attr":{"d":"M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"}}]})(props);
};
export function LuLightbulb (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5"}},{"tag":"path","attr":{"d":"M9 18h6"}},{"tag":"path","attr":{"d":"M10 22h4"}}]})(props);
};
export function LuTags (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M9 5H2v7l6.29 6.29c.94.94 2.48.94 3.42 0l3.58-3.58c.94-.94.94-2.48 0-3.42L9 5Z"}},{"tag":"path","attr":{"d":"M6 9.01V9"}},{"tag":"path","attr":{"d":"m15 5 6.3 6.3a2.4 2.4 0 0 1 0 3.4L17 19"}}]})(props);
};
export function LuPlug2 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"M9 2v6"}},{"tag":"path","attr":{"d":"M15 2v6"}},{"tag":"path","attr":{"d":"M12 17v5"}},{"tag":"path","attr":{"d":"M5 8h14"}},{"tag":"path","attr":{"d":"M6 11V8h12v3a6 6 0 1 1-12 0v0Z"}}]})(props);
};
export function LuChevronRightCircle (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"circle","attr":{"cx":"12","cy":"12","r":"10"}},{"tag":"path","attr":{"d":"m10 8 4 4-4 4"}}]})(props);
};
export function LuChevronLeftCircle (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"circle","attr":{"cx":"12","cy":"12","r":"10"}},{"tag":"path","attr":{"d":"m14 16-4-4 4-4"}}]})(props);
};
export function LuChevronsLeftRight (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"m9 7-5 5 5 5"}},{"tag":"path","attr":{"d":"m15 7 5 5-5 5"}}]})(props);
};
export function LuChevronLeft (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"d":"m15 18-6-6 6-6"}}]})(props);
};
export function LuTimer (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","strokeWidth":"2","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"line","attr":{"x1":"10","x2":"14","y1":"2","y2":"2"}},{"tag":"line","attr":{"x1":"12","x2":"15","y1":"14","y2":"11"}},{"tag":"circle","attr":{"cx":"12","cy":"14","r":"8"}}]})(props);
};