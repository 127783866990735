import { GenIcon } from '../lib';

export function TbBuildingBank (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M3 21l18 0"}},{"tag":"path","attr":{"d":"M3 10l18 0"}},{"tag":"path","attr":{"d":"M5 6l7 -3l7 3"}},{"tag":"path","attr":{"d":"M4 10l0 11"}},{"tag":"path","attr":{"d":"M20 10l0 11"}},{"tag":"path","attr":{"d":"M8 14l0 3"}},{"tag":"path","attr":{"d":"M12 14l0 3"}},{"tag":"path","attr":{"d":"M16 14l0 3"}}]})(props);
};
export function TbTestPipeOff (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M20 8.04a803.533 803.533 0 0 0 -4 3.96m-2 2c-1.085 1.085 -3.125 3.14 -6.122 6.164a2.857 2.857 0 0 1 -4.041 -4.04c3.018 -3 5.073 -5.037 6.163 -6.124m2 -2c.872 -.872 2.191 -2.205 3.959 -4"}},{"tag":"path","attr":{"d":"M7 13h6"}},{"tag":"path","attr":{"d":"M19 15l1.5 1.6m-.74 3.173a2 2 0 0 1 -2.612 -2.608"}},{"tag":"path","attr":{"d":"M15 3l6 6"}},{"tag":"path","attr":{"d":"M3 3l18 18"}}]})(props);
};
export function TbTrash (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M4 7l16 0"}},{"tag":"path","attr":{"d":"M10 11l0 6"}},{"tag":"path","attr":{"d":"M14 11l0 6"}},{"tag":"path","attr":{"d":"M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"}},{"tag":"path","attr":{"d":"M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"}}]})(props);
};
export function TbTrashOff (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M3 3l18 18"}},{"tag":"path","attr":{"d":"M4 7h3m4 0h9"}},{"tag":"path","attr":{"d":"M10 11l0 6"}},{"tag":"path","attr":{"d":"M14 14l0 3"}},{"tag":"path","attr":{"d":"M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l.077 -.923"}},{"tag":"path","attr":{"d":"M18.384 14.373l.616 -7.373"}},{"tag":"path","attr":{"d":"M9 5v-1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"}}]})(props);
};
export function TbListCheck (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M3.5 5.5l1.5 1.5l2.5 -2.5"}},{"tag":"path","attr":{"d":"M3.5 11.5l1.5 1.5l2.5 -2.5"}},{"tag":"path","attr":{"d":"M3.5 17.5l1.5 1.5l2.5 -2.5"}},{"tag":"path","attr":{"d":"M11 6l9 0"}},{"tag":"path","attr":{"d":"M11 12l9 0"}},{"tag":"path","attr":{"d":"M11 18l9 0"}}]})(props);
};
export function TbSend (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M10 14l11 -11"}},{"tag":"path","attr":{"d":"M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"}}]})(props);
};
export function TbSquareRoundedChevronDownFilled (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M12 2c-.218 0 -.432 .002 -.642 .005l-.616 .017l-.299 .013l-.579 .034l-.553 .046c-4.785 .464 -6.732 2.411 -7.196 7.196l-.046 .553l-.034 .579c-.005 .098 -.01 .198 -.013 .299l-.017 .616l-.004 .318l-.001 .324c0 .218 .002 .432 .005 .642l.017 .616l.013 .299l.034 .579l.046 .553c.464 4.785 2.411 6.732 7.196 7.196l.553 .046l.579 .034c.098 .005 .198 .01 .299 .013l.616 .017l.642 .005l.642 -.005l.616 -.017l.299 -.013l.579 -.034l.553 -.046c4.785 -.464 6.732 -2.411 7.196 -7.196l.046 -.553l.034 -.579c.005 -.098 .01 -.198 .013 -.299l.017 -.616l.005 -.642l-.005 -.642l-.017 -.616l-.013 -.299l-.034 -.579l-.046 -.553c-.464 -4.785 -2.411 -6.732 -7.196 -7.196l-.553 -.046l-.579 -.034a28.058 28.058 0 0 0 -.299 -.013l-.616 -.017l-.318 -.004l-.324 -.001zm-3.707 8.293a1 1 0 0 1 1.32 -.083l.094 .083l2.293 2.292l2.293 -2.292a1 1 0 0 1 1.32 -.083l.094 .083a1 1 0 0 1 .083 1.32l-.083 .094l-3 3a1 1 0 0 1 -1.32 .083l-.094 -.083l-3 -3a1 1 0 0 1 0 -1.414z","fill":"currentColor","strokeWidth":"0"}}]})(props);
};
export function TbSquareRoundedChevronUpFilled (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M12 2c-.218 0 -.432 .002 -.642 .005l-.616 .017l-.299 .013l-.579 .034l-.553 .046c-4.785 .464 -6.732 2.411 -7.196 7.196l-.046 .553l-.034 .579c-.005 .098 -.01 .198 -.013 .299l-.017 .616l-.004 .318l-.001 .324c0 .218 .002 .432 .005 .642l.017 .616l.013 .299l.034 .579l.046 .553c.464 4.785 2.411 6.732 7.196 7.196l.553 .046l.579 .034c.098 .005 .198 .01 .299 .013l.616 .017l.642 .005l.642 -.005l.616 -.017l.299 -.013l.579 -.034l.553 -.046c4.785 -.464 6.732 -2.411 7.196 -7.196l.046 -.553l.034 -.579c.005 -.098 .01 -.198 .013 -.299l.017 -.616l.005 -.642l-.005 -.642l-.017 -.616l-.013 -.299l-.034 -.579l-.046 -.553c-.464 -4.785 -2.411 -6.732 -7.196 -7.196l-.553 -.046l-.579 -.034a28.058 28.058 0 0 0 -.299 -.013l-.616 -.017l-.318 -.004l-.324 -.001zm-.707 7.293a1 1 0 0 1 1.32 -.083l.094 .083l3 3a1 1 0 0 1 -1.32 1.497l-.094 -.083l-2.293 -2.292l-2.293 2.292a1 1 0 0 1 -1.32 .083l-.094 -.083a1 1 0 0 1 -.083 -1.32l.083 -.094l3 -3z","fill":"currentColor","strokeWidth":"0"}}]})(props);
};
export function TbMailShare (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M13 19h-8a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6"}},{"tag":"path","attr":{"d":"M3 7l9 6l9 -6"}},{"tag":"path","attr":{"d":"M16 22l5 -5"}},{"tag":"path","attr":{"d":"M21 21.5v-4.5h-4.5"}}]})(props);
};
export function TbRulerMeasure (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M19.875 12c.621 0 1.125 .512 1.125 1.143v5.714c0 .631 -.504 1.143 -1.125 1.143h-15.875a1 1 0 0 1 -1 -1v-5.857c0 -.631 .504 -1.143 1.125 -1.143h15.75z"}},{"tag":"path","attr":{"d":"M9 12v2"}},{"tag":"path","attr":{"d":"M6 12v3"}},{"tag":"path","attr":{"d":"M12 12v3"}},{"tag":"path","attr":{"d":"M18 12v3"}},{"tag":"path","attr":{"d":"M15 12v2"}},{"tag":"path","attr":{"d":"M3 3v4"}},{"tag":"path","attr":{"d":"M3 5h18"}},{"tag":"path","attr":{"d":"M21 3v4"}}]})(props);
};
export function TbLayoutCards (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M4 4m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"}},{"tag":"path","attr":{"d":"M14 4m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"}}]})(props);
};
export function TbDiscount2 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M9 15l6 -6"}},{"tag":"circle","attr":{"cx":"9.5","cy":"9.5","r":".5","fill":"currentColor"}},{"tag":"circle","attr":{"cx":"14.5","cy":"14.5","r":".5","fill":"currentColor"}},{"tag":"path","attr":{"d":"M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"}}]})(props);
};
export function TbCategoryFilled (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M10 3h-6a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1 -1v-6a1 1 0 0 0 -1 -1z","strokeWidth":"0","fill":"currentColor"}},{"tag":"path","attr":{"d":"M20 3h-6a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1 -1v-6a1 1 0 0 0 -1 -1z","strokeWidth":"0","fill":"currentColor"}},{"tag":"path","attr":{"d":"M10 13h-6a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1 -1v-6a1 1 0 0 0 -1 -1z","strokeWidth":"0","fill":"currentColor"}},{"tag":"path","attr":{"d":"M17 13a4 4 0 1 1 -3.995 4.2l-.005 -.2l.005 -.2a4 4 0 0 1 3.995 -3.8z","strokeWidth":"0","fill":"currentColor"}}]})(props);
};
export function TbBarcodeOff (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M4 7v-1c0 -.552 .224 -1.052 .586 -1.414"}},{"tag":"path","attr":{"d":"M4 17v1a2 2 0 0 0 2 2h2"}},{"tag":"path","attr":{"d":"M16 4h2a2 2 0 0 1 2 2v1"}},{"tag":"path","attr":{"d":"M16 20h2c.551 0 1.05 -.223 1.412 -.584"}},{"tag":"path","attr":{"d":"M5 11h1v2h-1z"}},{"tag":"path","attr":{"d":"M10 11v2"}},{"tag":"path","attr":{"d":"M15 11v.01"}},{"tag":"path","attr":{"d":"M19 11v2"}},{"tag":"path","attr":{"d":"M3 3l18 18"}}]})(props);
};
export function TbBarcode (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M4 7v-1a2 2 0 0 1 2 -2h2"}},{"tag":"path","attr":{"d":"M4 17v1a2 2 0 0 0 2 2h2"}},{"tag":"path","attr":{"d":"M16 4h2a2 2 0 0 1 2 2v1"}},{"tag":"path","attr":{"d":"M16 20h2a2 2 0 0 0 2 -2v-1"}},{"tag":"path","attr":{"d":"M5 11h1v2h-1z"}},{"tag":"path","attr":{"d":"M10 11l0 2"}},{"tag":"path","attr":{"d":"M14 11h1v2h-1z"}},{"tag":"path","attr":{"d":"M19 11l0 2"}}]})(props);
};
export function TbClockHour3 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"}},{"tag":"path","attr":{"d":"M12 12h3.5"}},{"tag":"path","attr":{"d":"M12 7v5"}}]})(props);
};
export function TbDownload (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"}},{"tag":"path","attr":{"d":"M7 11l5 5l5 -5"}},{"tag":"path","attr":{"d":"M12 4l0 12"}}]})(props);
};
export function TbArchive (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"}},{"tag":"path","attr":{"d":"M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10"}},{"tag":"path","attr":{"d":"M10 12l4 0"}}]})(props);
};
export function TbArchiveOff (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M8 4h11a2 2 0 1 1 0 4h-7m-4 0h-3a2 2 0 0 1 -.826 -3.822"}},{"tag":"path","attr":{"d":"M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 1.824 -1.18m.176 -3.82v-7"}},{"tag":"path","attr":{"d":"M10 12h2"}},{"tag":"path","attr":{"d":"M3 3l18 18"}}]})(props);
};
export function TbCertificate2 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M12 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"}},{"tag":"path","attr":{"d":"M10 7h4"}},{"tag":"path","attr":{"d":"M10 18v4l2 -1l2 1v-4"}},{"tag":"path","attr":{"d":"M10 19h-2a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-2"}}]})(props);
};
export function TbPointFilled (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z","strokeWidth":"0","fill":"currentColor"}}]})(props);
};
export function TbPower (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M7 6a7.75 7.75 0 1 0 10 0"}},{"tag":"path","attr":{"d":"M12 4l0 8"}}]})(props);
};
export function TbArrowsExchange (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M7 10h14l-4 -4"}},{"tag":"path","attr":{"d":"M17 14h-14l4 4"}}]})(props);
};
export function TbMoneybag (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M9.5 3h5a1.5 1.5 0 0 1 1.5 1.5a3.5 3.5 0 0 1 -3.5 3.5h-1a3.5 3.5 0 0 1 -3.5 -3.5a1.5 1.5 0 0 1 1.5 -1.5z"}},{"tag":"path","attr":{"d":"M4 17v-1a8 8 0 1 1 16 0v1a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"}}]})(props);
};
export function TbFileTypeDocx (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M14 3v4a1 1 0 0 0 1 1h4"}},{"tag":"path","attr":{"d":"M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4"}},{"tag":"path","attr":{"d":"M2 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z"}},{"tag":"path","attr":{"d":"M17 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0"}},{"tag":"path","attr":{"d":"M9.5 15a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1 -3 0v-3a1.5 1.5 0 0 1 1.5 -1.5z"}},{"tag":"path","attr":{"d":"M19.5 15l3 6"}},{"tag":"path","attr":{"d":"M19.5 21l3 -6"}}]})(props);
};
export function TbBinaryTree (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M6 20a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"}},{"tag":"path","attr":{"d":"M16 4a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"}},{"tag":"path","attr":{"d":"M16 20a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"}},{"tag":"path","attr":{"d":"M11 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"}},{"tag":"path","attr":{"d":"M21 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"}},{"tag":"path","attr":{"d":"M5.058 18.306l2.88 -4.606"}},{"tag":"path","attr":{"d":"M10.061 10.303l2.877 -4.604"}},{"tag":"path","attr":{"d":"M10.065 13.705l2.876 4.6"}},{"tag":"path","attr":{"d":"M15.063 5.7l2.881 4.61"}}]})(props);
};
export function TbCursorText (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M10 12h4"}},{"tag":"path","attr":{"d":"M9 4a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3"}},{"tag":"path","attr":{"d":"M15 4a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3"}}]})(props);
};
export function TbArrowBigRightFilled (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M12.089 3.634a2 2 0 0 0 -1.089 1.78l-.001 2.586h-6.999a2 2 0 0 0 -2 2v4l.005 .15a2 2 0 0 0 1.995 1.85l6.999 -.001l.001 2.587a2 2 0 0 0 3.414 1.414l6.586 -6.586a2 2 0 0 0 0 -2.828l-6.586 -6.586a2 2 0 0 0 -2.18 -.434l-.145 .068z","strokeWidth":"0","fill":"currentColor"}}]})(props);
};
export function TbCircleDashedNumber1 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M8.56 3.69a9 9 0 0 0 -2.92 1.95"}},{"tag":"path","attr":{"d":"M3.69 8.56a9 9 0 0 0 -.69 3.44"}},{"tag":"path","attr":{"d":"M3.69 15.44a9 9 0 0 0 1.95 2.92"}},{"tag":"path","attr":{"d":"M8.56 20.31a9 9 0 0 0 3.44 .69"}},{"tag":"path","attr":{"d":"M15.44 20.31a9 9 0 0 0 2.92 -1.95"}},{"tag":"path","attr":{"d":"M20.31 15.44a9 9 0 0 0 .69 -3.44"}},{"tag":"path","attr":{"d":"M20.31 8.56a9 9 0 0 0 -1.95 -2.92"}},{"tag":"path","attr":{"d":"M15.44 3.69a9 9 0 0 0 -3.44 -.69"}},{"tag":"path","attr":{"d":"M10 10l2 -2v8"}}]})(props);
};
export function TbCircleDashedNumber2 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M8.56 3.69a9 9 0 0 0 -2.92 1.95"}},{"tag":"path","attr":{"d":"M3.69 8.56a9 9 0 0 0 -.69 3.44"}},{"tag":"path","attr":{"d":"M3.69 15.44a9 9 0 0 0 1.95 2.92"}},{"tag":"path","attr":{"d":"M8.56 20.31a9 9 0 0 0 3.44 .69"}},{"tag":"path","attr":{"d":"M15.44 20.31a9 9 0 0 0 2.92 -1.95"}},{"tag":"path","attr":{"d":"M20.31 15.44a9 9 0 0 0 .69 -3.44"}},{"tag":"path","attr":{"d":"M20.31 8.56a9 9 0 0 0 -1.95 -2.92"}},{"tag":"path","attr":{"d":"M15.44 3.69a9 9 0 0 0 -3.44 -.69"}},{"tag":"path","attr":{"d":"M10 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3"}}]})(props);
};
export function TbCircleDashedNumber3 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M8.56 3.69a9 9 0 0 0 -2.92 1.95"}},{"tag":"path","attr":{"d":"M3.69 8.56a9 9 0 0 0 -.69 3.44"}},{"tag":"path","attr":{"d":"M3.69 15.44a9 9 0 0 0 1.95 2.92"}},{"tag":"path","attr":{"d":"M8.56 20.31a9 9 0 0 0 3.44 .69"}},{"tag":"path","attr":{"d":"M15.44 20.31a9 9 0 0 0 2.92 -1.95"}},{"tag":"path","attr":{"d":"M20.31 15.44a9 9 0 0 0 .69 -3.44"}},{"tag":"path","attr":{"d":"M20.31 8.56a9 9 0 0 0 -1.95 -2.92"}},{"tag":"path","attr":{"d":"M15.44 3.69a9 9 0 0 0 -3.44 -.69"}},{"tag":"path","attr":{"d":"M10 8h2.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-2.5"}}]})(props);
};
export function TbCircleDashedNumber4 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M8.56 3.69a9 9 0 0 0 -2.92 1.95"}},{"tag":"path","attr":{"d":"M3.69 8.56a9 9 0 0 0 -.69 3.44"}},{"tag":"path","attr":{"d":"M3.69 15.44a9 9 0 0 0 1.95 2.92"}},{"tag":"path","attr":{"d":"M8.56 20.31a9 9 0 0 0 3.44 .69"}},{"tag":"path","attr":{"d":"M15.44 20.31a9 9 0 0 0 2.92 -1.95"}},{"tag":"path","attr":{"d":"M20.31 15.44a9 9 0 0 0 .69 -3.44"}},{"tag":"path","attr":{"d":"M20.31 8.56a9 9 0 0 0 -1.95 -2.92"}},{"tag":"path","attr":{"d":"M15.44 3.69a9 9 0 0 0 -3.44 -.69"}},{"tag":"path","attr":{"d":"M10 8v3a1 1 0 0 0 1 1h3"}},{"tag":"path","attr":{"d":"M14 8v8"}}]})(props);
};
export function TbCircleDashedNumber5 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M8.56 3.69a9 9 0 0 0 -2.92 1.95"}},{"tag":"path","attr":{"d":"M3.69 8.56a9 9 0 0 0 -.69 3.44"}},{"tag":"path","attr":{"d":"M3.69 15.44a9 9 0 0 0 1.95 2.92"}},{"tag":"path","attr":{"d":"M8.56 20.31a9 9 0 0 0 3.44 .69"}},{"tag":"path","attr":{"d":"M15.44 20.31a9 9 0 0 0 2.92 -1.95"}},{"tag":"path","attr":{"d":"M20.31 15.44a9 9 0 0 0 .69 -3.44"}},{"tag":"path","attr":{"d":"M20.31 8.56a9 9 0 0 0 -1.95 -2.92"}},{"tag":"path","attr":{"d":"M15.44 3.69a9 9 0 0 0 -3.44 -.69"}},{"tag":"path","attr":{"d":"M10 15a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-3v-4h4"}}]})(props);
};
export function TbCircleDashedNumber6 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M8.56 3.69a9 9 0 0 0 -2.92 1.95"}},{"tag":"path","attr":{"d":"M3.69 8.56a9 9 0 0 0 -.69 3.44"}},{"tag":"path","attr":{"d":"M3.69 15.44a9 9 0 0 0 1.95 2.92"}},{"tag":"path","attr":{"d":"M8.56 20.31a9 9 0 0 0 3.44 .69"}},{"tag":"path","attr":{"d":"M15.44 20.31a9 9 0 0 0 2.92 -1.95"}},{"tag":"path","attr":{"d":"M20.31 15.44a9 9 0 0 0 .69 -3.44"}},{"tag":"path","attr":{"d":"M20.31 8.56a9 9 0 0 0 -1.95 -2.92"}},{"tag":"path","attr":{"d":"M15.44 3.69a9 9 0 0 0 -3.44 -.69"}},{"tag":"path","attr":{"d":"M14 9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-3"}}]})(props);
};
export function TbCircleDashedNumber7 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M8.56 3.69a9 9 0 0 0 -2.92 1.95"}},{"tag":"path","attr":{"d":"M3.69 8.56a9 9 0 0 0 -.69 3.44"}},{"tag":"path","attr":{"d":"M3.69 15.44a9 9 0 0 0 1.95 2.92"}},{"tag":"path","attr":{"d":"M8.56 20.31a9 9 0 0 0 3.44 .69"}},{"tag":"path","attr":{"d":"M15.44 20.31a9 9 0 0 0 2.92 -1.95"}},{"tag":"path","attr":{"d":"M20.31 15.44a9 9 0 0 0 .69 -3.44"}},{"tag":"path","attr":{"d":"M20.31 8.56a9 9 0 0 0 -1.95 -2.92"}},{"tag":"path","attr":{"d":"M15.44 3.69a9 9 0 0 0 -3.44 -.69"}},{"tag":"path","attr":{"d":"M10 8h4l-2 8"}}]})(props);
};
export function TbCircleDashedNumber8 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M8.56 3.69a9 9 0 0 0 -2.92 1.95"}},{"tag":"path","attr":{"d":"M3.69 8.56a9 9 0 0 0 -.69 3.44"}},{"tag":"path","attr":{"d":"M3.69 15.44a9 9 0 0 0 1.95 2.92"}},{"tag":"path","attr":{"d":"M8.56 20.31a9 9 0 0 0 3.44 .69"}},{"tag":"path","attr":{"d":"M15.44 20.31a9 9 0 0 0 2.92 -1.95"}},{"tag":"path","attr":{"d":"M20.31 15.44a9 9 0 0 0 .69 -3.44"}},{"tag":"path","attr":{"d":"M20.31 8.56a9 9 0 0 0 -1.95 -2.92"}},{"tag":"path","attr":{"d":"M15.44 3.69a9 9 0 0 0 -3.44 -.69"}},{"tag":"path","attr":{"d":"M12 12h-1a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1"}}]})(props);
};
export function TbCircleDashedNumber9 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M8.56 3.69a9 9 0 0 0 -2.92 1.95"}},{"tag":"path","attr":{"d":"M3.69 8.56a9 9 0 0 0 -.69 3.44"}},{"tag":"path","attr":{"d":"M3.69 15.44a9 9 0 0 0 1.95 2.92"}},{"tag":"path","attr":{"d":"M8.56 20.31a9 9 0 0 0 3.44 .69"}},{"tag":"path","attr":{"d":"M15.44 20.31a9 9 0 0 0 2.92 -1.95"}},{"tag":"path","attr":{"d":"M20.31 15.44a9 9 0 0 0 .69 -3.44"}},{"tag":"path","attr":{"d":"M20.31 8.56a9 9 0 0 0 -1.95 -2.92"}},{"tag":"path","attr":{"d":"M15.44 3.69a9 9 0 0 0 -3.44 -.69"}},{"tag":"path","attr":{"d":"M10 15a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-6a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3"}}]})(props);
};
export function TbArrowsExchange2 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M17 10h-14l4 -4"}},{"tag":"path","attr":{"d":"M7 14h14l-4 4"}}]})(props);
};
export function TbHistory (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M12 8l0 4l2 2"}},{"tag":"path","attr":{"d":"M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5"}}]})(props);
};
export function TbClockHour4 (props) {
  return GenIcon({"tag":"svg","attr":{"viewBox":"0 0 24 24","strokeWidth":"2","stroke":"currentColor","fill":"none","strokeLinecap":"round","strokeLinejoin":"round"},"child":[{"tag":"path","attr":{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}},{"tag":"path","attr":{"d":"M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"}},{"tag":"path","attr":{"d":"M12 12l3 2"}},{"tag":"path","attr":{"d":"M12 7v5"}}]})(props);
};